declare var logPageLoadPerformanceUrl: string;

module m7performance {
    export function logPageLoadPerformance(pageName: string): void {

        $(window).on("load", (): void => {

            if (!window.performance || !performance.timing) {
                return;
            }

            // wrap logging function in setTimeout() to allow onload to complete and populate timing.loadEventEnd
            setTimeout(() => {
                let timing: PerformanceTiming = window.performance.timing;

                let serverResponseMs: number = timing.responseEnd - timing.navigationStart;
                let pageLoadMs: number = timing.loadEventEnd - timing.navigationStart;

                $.ajax({
                    url: logPageLoadPerformanceUrl,
                    type: "POST",
                    data: {
                        pageName,
                        serverResponseMs,
                        pageLoadMs
                    }
                });
            }, 0);
        });
    }
}